.footer{
    min-width:1024px;
    display: flex;
    align-items:center;
    justify-items: center;
    justify-content:flex-end;
    padding:10px;
    position: relative;
    width: 90%;
    height:10vh;
}
a:link, a:visited, a:active  {
    color:#AAA;
    text-decoration: none;
    cursor: pointer;
}