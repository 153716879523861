
.landing-container{
    background-color: white;
}
.float-container {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    height: fit-content;
    flex-wrap: nowrap;
}

.float-child {
    width: 40%;
    float: left;
    padding: 20px;
} 

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

#landing-img {
    color: white;
    -webkit-animation: fadein 4s ease-in normal ;
    -moz-animation: fadein 4s ease-in  normal ;
    animation: fadein 4s ease-in normal;
  
}

