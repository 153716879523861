.button {
  padding: 10px;
  min-height: 50px;
  min-width: 200px;
  border-radius: 10px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #fff;
}

button.primary{
  background-color: #0048be;
  color:white;

}

button.secondary{
    border-color: #0048be;
    background-color: aliceblue;
    color: #000
}