.section-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    flex-wrap: wrap;
    width: 100%;
    margin:10px 0 0 0
}

.section-child {
    width: 50%;
    float: left;
} 