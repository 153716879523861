.title{
    text-align: left;
    text-transform: capitalize;
    margin: 0;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color:white ;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: .2rem;
    }
  .subtitle{
    padding-top: 5px;
    text-align: left;
    margin: 0;
    margin-left: 15px;
    font-weight: 400;
    letter-spacing: normal;
  }
  .pageHeader{
    display: flex;
    flex-direction: row;
    background-color: black;
    color: azure;
    width: 100%;
    margin: 0;
    min-height: 10vh;
    align-items: center;
    justify-content: center;
  }
  .pageTitle{
    display:flex;
    padding:10px;
    min-width: 1024px;
  }